import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AddressResult } from 'src/app/modules/shared/components/molecules/json-search/models/json-search';
import { createBusinessProfile, storeBusinessProfile } from '../../../actions/authentication';
import {
  PROVINCES_DROPDOWN_DATA,
  INDUSTRIES_DROPDOWN_DATA,
} from '../../../constants/business-info';
import { BusinessProfile, DropDownItem } from '../../../models/authentication.models';
import { InitialState } from '../../../reducers/authentication';
import {
  businessProfile,
  createBusinessProfileInProgress,
} from '../../../selectors/authentication';
import { FormValidationService } from '../../../../shared/services/form-validation.service';
import { AnalyticsEvent } from '@core/models/analyticsEvent';
import { AnalyticsService } from '@core/services/common/analytics.service';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./../business-info.scss'],
})
export class CreateProfilePageComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  provinces = PROVINCES_DROPDOWN_DATA;
  industries = INDUSTRIES_DROPDOWN_DATA;
  selectedProvince: DropDownItem;
  selectedIndustry: DropDownItem;
  submitAttempted = false;
  suburb: string;

  @ViewChild('postalCode', { read: ElementRef }) postalCode: ElementRef;
  @ViewChild('cityOrTown', { read: ElementRef }) cityOrTown: ElementRef;

  businessProfile$: Observable<any> = this.store.select(businessProfile);
  subscriptions: Subscription[] = [];

  createBusinessProfileInProgress$: Observable<boolean> = this.store.select(
    createBusinessProfileInProgress,
  );

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formValidationService: FormValidationService,
    private store: Store<InitialState>,
    private router: Router,
    private analyticsService: AnalyticsService,
  ) {
    this.form = this.formBuilder.group({
      registeredBusinessName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
          this.formValidationService.isWhiteSpace,
        ],
      ],
      businessTradingName: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
          this.formValidationService.isWhiteSpace,
        ],
      ],
      businessRegistrationNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
          this.formValidationService.isWhiteSpace,
        ],
      ],
      businessPhoneNumber: ['', [Validators.required, this.formValidationService.phoneNumber]],
      customerCareEmail: [
        '',
        [Validators.required, this.formValidationService.email, Validators.maxLength(255)],
      ],
      customerCareNumber: ['', [Validators.required, this.formValidationService.phoneNumber]],
      vatNumber: ['', [this.formValidationService.vatNumber, Validators.maxLength(255)]],
      businessURL: ['', [this.formValidationService.url, Validators.maxLength(255)]],
      address1: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
          this.formValidationService.isWhiteSpace,
        ],
      ],
      address2: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
          this.formValidationService.isWhiteSpace,
        ],
      ],
      postalCode: [
        { value: '', disabled: true },
        [Validators.required, this.formValidationService.postalCode],
      ],
      cityOrTown: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(255),
          this.formValidationService.isWhiteSpace,
        ],
      ],
      sameShippingAddress: [true],
    });
  }

  ngOnInit(): void {
    this.updateFormWithStoredData();
    this.monitorManualPageNavigation();
    const event = new AnalyticsEvent('signup', 'view', 'create_profile_view', 'in_progress', 4, 8);
    this.analyticsService.logEvent(event);
  }

  updateFormWithStoredData(): void {
    const businessProfileSubscription: Subscription = this.businessProfile$
      .pipe(take(1))
      .subscribe((profile: BusinessProfile) => {
        if (profile) {
          this.form.patchValue({
            registeredBusinessName: profile.registeredBusinessName,
            businessTradingName: profile.businessTradingName,
            businessRegistrationNumber: profile.businessRegistrationNumber,
            businessPhoneNumber: profile.businessPhoneNumber,
            customerCareEmail: profile.customerCareEmail,
            customerCareNumber: profile.customerCareNumber,
            vatNumber: profile.vatNumber,
            businessURL: profile.businessURL,
            address1: profile.address?.address1,
            address2: profile.address?.address2,
            postalCode: profile.address?.postalCode,
            cityOrTown: profile.address?.cityOrTown,
            sameShippingAddress: profile.sameShippingAddress,
          });
          if (profile.address?.province) {
            this.selectedProvince = {
              label: profile.address.province,
              value: profile.address.province,
            };
          }
          if (profile.industry) {
            this.selectedIndustry = {
              label: profile.industry,
              value: profile.industry,
            };
          }

          if (profile.address?.suburb) {
            this.suburb = profile.address.suburb;
          }
        }
      });
    this.subscriptions.push(businessProfileSubscription);
  }

  onSubmit(): void {
    this.submitAttempted = true;
    if (this.form.invalid) {
      Object.keys(this.form.controls).forEach((field) => {
        this.form.get(field).markAsTouched({ onlySelf: true });
      });
      return;
    }

    if (!this.selectedProvince) return;

    if (!this.suburb) return;

    const profileData = this.getProfileData();
    this.store.dispatch(createBusinessProfile({ profileData: profileData }));
  }

  setProvince(option: DropDownItem): void {
    this.selectedProvince = option;
  }

  setIndustry(option: DropDownItem): void {
    this.selectedIndustry = option;
  }

  monitorManualPageNavigation(): void {
    const routerSubscription = this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate' && event.url === '/welcome') {
        this.storeCurrentFormData();
      }
    });
    this.subscriptions.push(routerSubscription);
  }

  goBack(): void {
    this.storeCurrentFormData();
    this.router.navigate(['/welcome']);
  }

  storeCurrentFormData(): void {
    const profileData = this.getProfileData();
    this.store.dispatch(storeBusinessProfile({ profileData: profileData }));
  }

  getProfileData(): BusinessProfile {
    return {
      registeredBusinessName: this.form.controls.registeredBusinessName.value,
      businessTradingName: this.form.controls.businessTradingName.value,
      businessRegistrationNumber: this.form.controls.businessRegistrationNumber.value,
      businessPhoneNumber: this.form.controls.businessPhoneNumber.value,
      customerCareEmail: this.form.controls.customerCareEmail.value,
      customerCareNumber: this.form.controls.customerCareNumber.value,
      vatNumber: this.form.controls.vatNumber.value,
      businessURL: this.form.controls.businessURL.value,
      address: {
        address1: this.form.controls.address1.value,
        address2: this.form.controls.address2.value,
        suburb: this.suburb,
        postalCode: this.form.controls.postalCode.value,
        cityOrTown: this.form.controls.cityOrTown.value,
        province: this.selectedProvince?.value,
      },
      sameShippingAddress: this.form.controls.sameShippingAddress.value,
      industry: this.selectedIndustry?.value,
    };
  }

  setAddress(address: AddressResult) {
    this.suburb = address.suburb;
    if (address.city) {
      this.form.get('cityOrTown').setValue(address.city);
      this.cityOrTown.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true }));
      this.form.get('cityOrTown').disable();
    } else {
      this.form.get('cityOrTown').enable();
    }

    if (address.postalCode) {
      this.form.get('postalCode').setValue(address.postalCode);
      this.postalCode.nativeElement.dispatchEvent(new CustomEvent('change', { bubbles: true }));
      this.form.get('postalCode').disable();
    } else {
      this.form.get('postalCode').enable();
    }
    if (address.province) {
      this.selectedProvince = {
        label: address.province,
        value: address.province,
      };
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
