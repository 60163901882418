<div class="business-info">
  <h1 class="business-info__heading">Create your profile</h1>

  <form validate (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="business-info__form-container">
      <div class="business-info__img-container">
        <img class="business-info__img" src="assets/images/auth/buildings.svg" />
      </div>
      <div class="business-info__business-info">
        <h2 class="business-info__title">Business Details</h2>

        <div class="business-info__input-container">
          <pargo-input
            label="Registered business name"
            formName="registeredBusinessName"
            [form]="form"
          >
            <input class="input" type="text" formControlName="registeredBusinessName" />
          </pargo-input>
        </div>

        <div class="business-info__input-container">
          <pargo-input
            label="Business registration number / ID"
            formName="businessRegistrationNumber"
            [form]="form"
          >
            <input class="input" type="text" formControlName="businessRegistrationNumber" />
          </pargo-input>
        </div>

        <div class="business-info__input-container">
          <pargo-input label="Business trading name" formName="businessTradingName" [form]="form">
            <input class="input" type="text" formControlName="businessTradingName" />
          </pargo-input>
        </div>

        <div class="business-info__input-container">
          <pargo-input label="Business phone number" formName="businessPhoneNumber" [form]="form">
            <input class="input" type="text" formControlName="businessPhoneNumber" />
          </pargo-input>
        </div>

        <div class="business-info__input-container">
          <pargo-input label="Customer care email" formName="customerCareEmail" [form]="form">
            <input class="input" type="email" formControlName="customerCareEmail" />
          </pargo-input>
        </div>

        <div class="business-info__input-container">
          <pargo-input
            label="Customer care phone number"
            formName="customerCareNumber"
            [form]="form"
          >
            <input class="input" type="text" formControlName="customerCareNumber" />
          </pargo-input>
        </div>

        <div class="business-info__input-container">
          <pargo-input label="VAT number (optional)" formName="vatNumber" [form]="form">
            <input class="input" type="text" formControlName="vatNumber" />
          </pargo-input>
        </div>

        <div class="business-info__dropdown">
          <pargo-dropdown
            label="Your industry? (optional)"
            small="true"
            [value]="selectedIndustry"
            [items]="industries"
            (change)="setIndustry($event)"
          ></pargo-dropdown>
        </div>

        <div class="business-info__input-container">
          <pargo-input label="Website URL (optional)" formName="businessURL" [form]="form">
            <input class="input" type="url" formControlName="businessURL" />
          </pargo-input>
        </div>
      </div>
      <div class="business-info__business-info">
        <h2 class="business-info__title">Business Address</h2>

        <div class="business-info__input-container">
          <pargo-input label="Address line 1" formName="address1" [form]="form">
            <input class="input" type="text" formControlName="address1" />
          </pargo-input>
        </div>
        <div class="business-info__input-container">
          <pargo-input label="Address Line 2" formName="address2" [form]="form">
            <input class="input" type="text" formControlName="address2" />
          </pargo-input>
        </div>
        <div class="business-info__input-container">
          <app-json-search
            label="Search by city and suburb"
            processType="w2p"
            [preliminarySuburb]="suburb"
            [returnUserTypedSuburb]="true"
            [suburbRequiredError]="submitAttempted && !suburb"
            [showPostalCodeInput]="false"
            (search)="setAddress($event)"
          >
          </app-json-search>
        </div>
        <div class="business-info__input-container business-info__input-container--less-margin">
          <pargo-input label="Postal code" formName="postalCode" [form]="form">
            <input class="input" type="text" formControlName="postalCode" #postalCode />
          </pargo-input>
        </div>
        <div class="business-info__input-container">
          <pargo-input label="City / Town" formName="cityOrTown" [form]="form">
            <input class="input" type="text" formControlName="cityOrTown" #cityOrTown />
          </pargo-input>
        </div>
        <div class="business-info__dropdown">
          <pargo-dropdown
            label="Province"
            small="true"
            [items]="provinces"
            [value]="selectedProvince"
            (change)="setProvince($event)"
          ></pargo-dropdown>
          <small class="input__error" *ngIf="submitAttempted && !selectedProvince">
            This field is required.
          </small>
        </div>
        <div class="business-info__warehouse-address-check">
          <label class="business-info__warehouse-address-check-label">
            <input type="checkbox" formControlName="sameShippingAddress" />
            <span class="business-info__warehouse-address-check-text"
              >Details same as warehouse (I will also ship from this address)</span
            >
          </label>
        </div>
      </div>
    </div>

    <div class="business-info__buttons-container">
      <pargo-button
        class="business-info__buttons business-info__buttons--web-only"
        secondary="true"
        (click)="goBack()"
        label="Back"
      >
      </pargo-button>
      <pargo-button
        [loading]="createBusinessProfileInProgress$ | async"
        [disabled]="createBusinessProfileInProgress$ | async"
        class="business-info__buttons"
        type="submit"
        label="Continue"
      >
      </pargo-button>
    </div>
  </form>
</div>
