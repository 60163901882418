<section *ngIf="isMobile" class="warehouse-details-mobile warehouse-details">
  <div class="warehouse-details__slider">
    <div
      class="warehouse-details__chevron-container warehouse-details__chevron-container--left"
      *ngIf="warehouses && warehouses.length > 1"
      (click)="goLeft()"
    >
      <mat-icon class="warehouse-details__chevron warehouse-details__chevron--left">
        keyboard_arrow_left
      </mat-icon>
    </div>
    <ul class="warehouse-details__list" (swipeLeft)="goRight()" (swipeRight)="goLeft()">
      <li
        [class]="'warehouse-details__item my-class slide-' + i"
        *ngFor="let warehouse of warehouses; let i = index"
      >
        <div
          [class]="selected === warehouse.reference ? 'warehouse-details__item-container warehouse-details__item-container--selected' : 'warehouse-details__item-container'"
        >
          <mat-icon class="warehouse-details__icon" svgIcon="warehouse-pargo"></mat-icon>

          <div class="warehouse-details__warehouse">
            <h2 class="warehouse-details__name">Address <span>{{ warehouse.reference }}</span></h2>
            <p class="warehouse-details__contact" *ngIf="warehouse.contactName">
              {{ warehouse.contactName }}
            </p>
            <div
              class="warehouse-details__contact-details"
              *ngIf="warehouse.phoneNumber || warehouse.email"
            >
              <p class="warehouse-details__telephone" *ngIf="warehouse.phoneNumber">
                <mat-icon class="warehouse-details__contact-icon" svgIcon="phone-pargo"></mat-icon>
                <span>{{ warehouse.phoneNumber }}</span>
              </p>
              <p class="warehouse-details__email" *ngIf="warehouse.email">
                <mat-icon class="warehouse-details__contact-icon" svgIcon="email-pargo"></mat-icon>
                <span>{{ warehouse.email }}</span>
              </p>
            </div>
            <div class="warehouse-details__address">
              <mat-icon class="warehouse-details__contact-icon" svgIcon="pin-pargo"></mat-icon>
              <div class="warehouse-details__address-container">
                <p
                  class="warehouse-details__street"
                  *ngIf="warehouse.address1 || warehouse.address2 || warehouse.postalCode"
                >
                  {{ createAddressesText(warehouse) }} {{ warehouse.postalCode }}
                </p>
                <p
                  class="warehouse-details__city"
                  *ngIf=" warehouse.suburb || warehouse.city || warehouse.province"
                >
                  {{ warehouse.suburb + ', ' }} {{ warehouse.city + ', ' }} {{ warehouse.province }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div
      class="warehouse-details__chevron-container warehouse-details__chevron-container--right"
      *ngIf="warehouses && warehouses.length > 1"
      (click)="goRight()"
    >
      <mat-icon class="warehouse-details__chevron warehouse-details__chevron--right">
        keyboard_arrow_right
      </mat-icon>
    </div>
  </div>
  <ul class="warehouse-details__slider-list" *ngIf="warehouses && warehouses.length > 1">
    <li
      class="warehouse-details__indicator-item"
      *ngFor="let warehouse of warehouses; let i = index"
    >
      <span
        (click)="goToSlide(i)"
        [class]="current === i  ? 'warehouse-details__indicator warehouse-details__indicator--active' : 'warehouse-details__indicator'"
      >
      </span>
    </li>
  </ul>
  <button
    type="button"
    [class]="warehouses[current] && selected === warehouses[current].reference ? 'warehouse-details__select warehouse-details__select--selected' : 'warehouse-details__select'"
    (click)="selectWarehouse(warehouses[current].reference)"
    *ngIf="warehouses && warehouses.length > 1 && warehouses[current] && warehouses[current].reference && selected && warehousesForOrderCreation"
  >
    <mat-icon
      class="warehouse-details__select-icon"
      *ngIf="warehouses[current] && selected === warehouses[current].reference"
      >check
    </mat-icon>
    {{ warehouses[current] && selected === warehouses[current].reference ? 'Selected' : 'Select' }}
  </button>
</section>

<section *ngIf="!isMobile" class="warehouse-details-web">
  <pargo-container>
    <pargo-dashboard-box heading="warehouses">
      <pargo-information-table
        [data]="tableData"
        [headers]="tableHeaders"
        [sortableColumns]="sortableColumns"
        [columnWidths]="columnWidths"
        [showIndex]="false"
        (updateSelectedWarehouse)="updateSelectedWarehouse($event)"
        (warehouseToEdit)="selectWarehouseToEdit($event)"
      >
      </pargo-information-table>
    </pargo-dashboard-box>
  </pargo-container>
  <br />
</section>
