import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { CoreService } from '../services/core.service';

import {
  ADD_PROFILE,
  ADD_TRANSACTIONS,
  GET_TRANSACTIONS,
  INIT_APPLICATION,
  NOT_AUTHENTICATED,
  SHOW_NAV_BAR,
} from '../actions/core';

import {
  AUTHENTICATION_EFFECT_NAVIGATION,
  CREATE_USER_ACCOUNT_CONFIRMATION,
  CREATE_USER_ACCOUNT_STORE_DATA,
  SIGN_UP_IN_PROGRESS,
  STORE_BUSINESS_PROFILE,
  STORE_WAREHOUSE,
} from '../../modules/authentication/actions/authentication';
import {
  AUTH_REDIRECTION,
  INIT_APPLICATION as GLOBAL_INIT_APPLICATION,
  THROW_RESPONSE_ERR,
} from '../actions/globals';

import { Router } from '@angular/router';
import { createProfileObject, reOrderWarehouses } from '@core/utils';
import { throwError } from 'rxjs';
import {
  BusinessProfile,
  ProfileStatus,
  SupplierInfo,
  UserAccountStatus,
  Warehouse,
} from 'src/app/modules/authentication/models/authentication.models';
import { authenticated } from '../../modules/authentication/selectors/authentication';
import { firstLogin } from '../selectors/core';

@Injectable()
export class CoreEffects {
  constructor(
    private actions: Actions<any>,
    private coreService: CoreService,
    private store: Store<any>,
    private router: Router,
  ) {}

  public globalInit$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(INIT_APPLICATION, GLOBAL_INIT_APPLICATION),
      withLatestFrom(this.store.select(authenticated)),
      concatMap((props) => {
        const [_, authenticated] = props;

        if (authenticated) {
          return this.coreService.getProfile().pipe(
            switchMap((result) => {
              const { data, success }: any = result;
              if (success === true && data[0]) {
                const { related, user } = data[0];
                const actions = [];
                actions.push({
                  type: AUTH_REDIRECTION,
                  supplier: data[0],
                });

                if (related.type === ProfileStatus.Supplier) {
                  const warehouses = reOrderWarehouses(related.warehouses);
                  const profile = createProfileObject(related, user);
                  actions.push(
                    {
                      type: ADD_PROFILE,
                      profile: profile,
                      payments: related.payments,
                      credits: related.credits,
                      processes: related.activeProcesses,
                      warehouses: warehouses,
                    },
                    {
                      type: GET_TRANSACTIONS,
                    },
                  );
                }

                return actions;
              }
            }),
          );
        } else {
          return [{ type: NOT_AUTHENTICATED }];
        }
      }),
    ),
  );

  public globalInitTransactions$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(GET_TRANSACTIONS),
      withLatestFrom(this.store.select(authenticated)),
      concatMap((props) => {
        const [_, authenticated] = props;
        if (authenticated) {
          return this.coreService.getTransactions().pipe(
            switchMap((result: any) => {
              const { data }: any = result;
              if (data) {
                return [{ type: ADD_TRANSACTIONS, transactions: data }];
              }
            }),
            catchError((error: any) => {
              return [{ type: ADD_TRANSACTIONS, transactions: [] }];
            }),
          );
        } else {
          return [{ type: NOT_AUTHENTICATED }];
        }
      }),
    ),
  );

  public globalAuthRedirection$: any = createEffect(() =>
    this.actions.pipe(
      ofType<any>(AUTH_REDIRECTION),
      map((action) => action.supplier),
      concatMap((supplier) => {
        return this.store.select(firstLogin).pipe(
          take(1),
          switchMap((isFirstLogin) => {
            if (supplier.related.type === ProfileStatus.Supplier) {
              if (isFirstLogin) {
                return [
                  { type: SIGN_UP_IN_PROGRESS },
                  { type: AUTHENTICATION_EFFECT_NAVIGATION, url: '/auth/sign-up-completion' },
                ];
              }
              if (this.appIsInitializedFromAuthFlow()) {
                return [
                  { type: AUTHENTICATION_EFFECT_NAVIGATION, url: '/dashboard/home' },
                  { type: SHOW_NAV_BAR },
                ];
              }
              return [];
            } else if (this.profileNotCompleted(supplier.related)) {
              return [
                { type: SIGN_UP_IN_PROGRESS },
                {
                  type: CREATE_USER_ACCOUNT_STORE_DATA,
                  email: supplier.user.attributes.email,
                  name: supplier.user.attributes.name,
                },
                { type: AUTHENTICATION_EFFECT_NAVIGATION, url: '/auth/create-profile' },
              ];
            } else if (this.warehouseNotCompleted(supplier.related)) {
              const profile = this.mapProfile(supplier.related);
              return [
                { type: SIGN_UP_IN_PROGRESS },
                {
                  type: CREATE_USER_ACCOUNT_STORE_DATA,
                  email: supplier.user.attributes.email,
                  name: supplier.user.attributes.name,
                },
                { type: AUTHENTICATION_EFFECT_NAVIGATION, url: '/auth/create-warehouse' },
                { type: STORE_BUSINESS_PROFILE, profileData: profile },
              ];
            } else {
              const profile = this.mapProfile(supplier.related);
              const warehouse = this.mapWarehouse(supplier.related);
              return [
                { type: SIGN_UP_IN_PROGRESS },
                {
                  type: CREATE_USER_ACCOUNT_STORE_DATA,
                  email: supplier.user.attributes.email,
                  name: supplier.user.attributes.name,
                },
                { type: STORE_BUSINESS_PROFILE, profileData: profile },
                { type: STORE_WAREHOUSE, warehouseData: warehouse },
                { type: AUTHENTICATION_EFFECT_NAVIGATION, url: '/auth/create-warehouse' },
                {
                  type: CREATE_USER_ACCOUNT_CONFIRMATION,
                  accountConfirmationStatus: UserAccountStatus.Unconfirmed,
                },
              ];
            }
          }),
        );
      }),
    ),
  );

  public throwResponseErr$: any = createEffect(
    () =>
      this.actions.pipe(
        ofType<any>(THROW_RESPONSE_ERR),
        map((action) => action.error),
        concatMap((error) => {
          return throwError(error);
        }),
      ),
    { dispatch: false },
  );

  private profileNotCompleted(profile: SupplierInfo): boolean {
    //all required profile fields need to have data, hence if 1 field doesn't have data, profile form was not completed
    return !profile.address.main.address_1;
  }

  private warehouseNotCompleted(profile: SupplierInfo): boolean {
    //all required warehouse fields need to have data, hence if 1 field doesn't have data, warehouse form was not completed
    return !profile.address['1'];
  }

  private mapProfile(supplier: SupplierInfo): BusinessProfile {
    return {
      registeredBusinessName: supplier.name,
      businessTradingName: supplier.trading_as,
      businessPhoneNumber: supplier.phone_number,
      vatNumber: supplier.vat_number,
      businessURL: supplier.business_url,
      businessRegistrationNumber: supplier.registration_number,
      industry: supplier.industry,
      customerCareNumber: supplier.support.phone_number,
      customerCareEmail: supplier.support.email,
      address: {
        address1: supplier.address.main.address_1,
        address2: supplier.address.main.address_2,
        suburb: supplier.address.main.suburb,
        postalCode: supplier.address.main.postal_code,
        cityOrTown: supplier.address.main.city,
        province: supplier.address.main.province,
      },
    };
  }

  private mapWarehouse(supplier: SupplierInfo): Warehouse {
    return {
      wareHouseContactPerson: supplier.address['1'].contact.name,
      warehouseEmailAddress: supplier.address['1'].contact.email,
      warehousePhoneNumber: supplier.address['1'].contact.phone_number,
      address: {
        address1: supplier.address['1'].address_1 ? supplier.address['1'].address_1 : '',
        address2: supplier.address['1'].address_2 ? supplier.address['1'].address_2 : '',
        suburb: supplier.address['1'].suburb,
        postalCode: supplier.address['1'].postal_code,
        cityOrTown: supplier.address['1'].city,
        province: supplier.address['1'].province,
      },
    };
  }

  private appIsInitializedFromAuthFlow(): boolean {
    return this.router.url === '/login' || this.router.url.includes('auth');
  }
}
